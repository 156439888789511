import { Link } from 'gatsby';
import React from 'react';
import { Helmet } from 'react-helmet';

import './404.scss';

const FourOhFour: React.FC = () => (
  <main className="four-oh-four">
    <Helmet title="Página Não Encontrada - Experiências Incríveis" />

    <h1>Eita Giovana...</h1>
    <h4>Essa página não foi encontrada <span role="img" aria-label="emoji">😵</span></h4>

    <Link to="/">
      Ok, deixa pra lá...
    </Link>
  </main>
);

export default FourOhFour;
